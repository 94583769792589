import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import {Message} from "../login/login-interface/interface";
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    public router: Router, private data: DataService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if(localStorage.getItem('message') === Message.OTP_REQUIRED){
      return true;
    }
    else if (localStorage.getItem('access_token') && localStorage.getItem('loginType') === 'Vendor') {
      return true;
    }
    this.router.navigate(['/admin/login']);
    return true;
  }
}
