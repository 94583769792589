import {AfterViewInit, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren,} from '@angular/core';
import {ApiService} from '../../../services/api.service';
import {DataService} from '../../../services/data.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {NgDialogAnimationService} from 'ng-dialog-animation';
import {debounce} from 'lodash';
import {fromEvent} from 'rxjs';
import {MatPaginator} from '@angular/material/paginator';
import {debounceTime, distinctUntilChanged, filter, map, startWith, tap} from 'rxjs/operators';
import {IsDataUpdatedService} from '../../../services/isDataUpdated.service';
import {CreateConsultantsComponent} from "./create-consultants/create-consultants.component";
import {PopUpComponent} from "../../../util/pop-up/pop-up.component";
import {Consultant, DateSearchCriteria, Skill} from "../../../shared/interfaces";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {Observable} from "rxjs/internal/Observable";
import {MatDatepicker} from "@angular/material/datepicker";
import {FormControl} from "@angular/forms";
import {ResetFiltersService} from "../../../services/resetFilters.service";

interface GetAllConsultant {
  consultantId: string;
  consultantName: string;
  title: string;
  experience: {
    min: number;
    max: number;
  }[];
  vendorName: string;
  skills: number[];
  query: string;
  statuses: string[];
  locations: number[];
  spocIds: number[];
  dateSearchCriteria: DateSearchCriteria;
  featured: boolean | null;
  searchEnable: boolean;
  active: boolean;
}

@Component({
  selector: 'app-manage-consultants',
  templateUrl: './manage-consultants.component.html',
  styleUrls: ['./manage-consultants.component.css']
})

export class ManageConsultantsComponent implements OnInit, AfterViewInit {
  skillControl = new FormControl('');
  filteredSkills: Observable<Skill[]>;
  disabledSkills: Skill[] = [];
  skillsRes: Skill[] = [];
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  consultants: Consultant[] = [];
  currentPage = 0;
  showLoader: boolean = true;
  search = '';
  showSearchEnable: boolean = true;
  showFeatured: boolean | null = null;
  showActive: boolean = true;
  pageSize = 12;
  totalEmployee = 0;
  selectedItemsExperience: { min: number, max: number }[] = [];
  selectedItemsConsultantStatus: string[] = [];
  selectedItemsLocationPreference: string[] = [];
  consultantId: string = '';
  consultantName: string = '';
  title: string = '';
  vendorsName: string = '';
  skills: any[] = [];
  filterType: string = 'CREATED';
  selectedSkill: Skill[] = [];
  SkillsList: any[] = [];
  @ViewChildren('dropdownExperience, dropdownSkills, dropdownConsultantStatus, dropdownLocationPreference,dropdownDate') dropdowns!: QueryList<ElementRef>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', {static: true}) input: ElementRef | any;
  @ViewChild('picker') picker1: MatDatepicker<any>;
  @ViewChild('picker1') picker2: MatDatepicker<any>;
  selectedFromDate: string = '';
  selectedToDate: string = '';
  searchFields: string[] = ['consultantId', 'consultantName', 'title', 'ExperienceYear', 'vendorName',
    'skills', 'statuses', 'locationsPref', 'spocIds', 'dateSearchCriteria']

  filters: GetAllConsultant = {
    consultantId: '',
    consultantName: '',
    title: '',
    experience: [],
    vendorName: '',
    skills: [],
    query: '',
    statuses: [],
    locations: [],
    spocIds: [],
    dateSearchCriteria: {
      filterType: 'CREATED',
      from: '',
      to: ''
    },
    featured: false,
    searchEnable: true,
    active: true,
  };

  constructor(
    private service: ApiService,
    private dataService: DataService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private isDataUpdatedService: IsDataUpdatedService,
    private resetFilters: ResetFiltersService,
  ) {
  }

  ngOnInit(): void {
    this.showLoader = false;
    this.getAllConsultants(this.currentPage, this.pageSize, this.filters);
    this.getAllConsultants = debounce<any>(this.getAllConsultants, 600);
    this.getSkillType();
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => {
        this.getAllConsultants(this.paginator.pageIndex, this.paginator.pageSize, this.filters);
      })
    ).subscribe();

    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getAllConsultants(this.paginator.pageIndex, this.paginator.pageSize, this.filters))
        )
        .subscribe();
    }
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string | Skill): Skill[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    // this.exactMatchFound = this.skillsRes.some(skill => skill.title.toLowerCase() == filterValue);
    return this.skillsRes.filter(skill =>
      skill.title.toLowerCase().includes(filterValue)
    );
  }


  isSelected(option: string, selectedItems: string[]): boolean {
    return selectedItems.includes(option);
  }

  getAllConsultants(pageNumber: number, size: number, payload: any): void {
    this.showLoader = true;
    this.service.getAllConsultants(pageNumber, size, payload).subscribe({
      next: res => {
        this.consultants = res.content;
        if (res.content && res.content.primarySkills) {
          this.SkillsList = this.consultants.map((e: any) => e.primarySkills?.map((e: any) => e.title));
        } else {
          this.SkillsList = [];
        }

        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.pageSize = res.page.size;
        this.totalEmployee = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  toggleSearchEnableConsultant(event: any) {
    this.filters.searchEnable = event.checked;
    this.getAllConsultants(this.currentPage, this.pageSize, this.filters);
  }

  toggleFeaturedConsultant(event: any) {
    this.filters.featured = event.checked;
    this.getAllConsultants(this.currentPage, this.pageSize, this.filters);
  }

  toggleActiveConsultant(event: any) {
    this.filters.active = event.checked
    this.getAllConsultants(this.currentPage, this.pageSize, this.filters);
  }

  downloadReport() {
    const timestamp = new Date().toISOString().replace(/[-:.]/g, "");
    const fileName = `Consultant_Report${timestamp}.csv`;
    this.service.downloadConsultantsReport(
      this.search,
      this.showSearchEnable,
      this.showActive,
      this.selectedItemsExperience,
      {
        filterType: this.filterType,
        from: this.selectedFromDate,
        to: this.selectedToDate
      },
      this.selectedSkill.map((res) => res.id),
      this.selectedItemsLocationPreference,
      this.selectedItemsConsultantStatus,
      this.vendorsName,
      this.consultantName,
      this.title,
      this.consultantId).subscribe(blob => {
      const a = document.createElement('a');
      const objectUrl = URL.createObjectURL(blob);
      a.href = objectUrl;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(objectUrl);
    });
  }

  openDialogs(action: string, element: any): void {
    if (action === 'delete') {
      const dialogRef = this.dialog.open(PopUpComponent, {
        data: {
          message: 'Are you sure to delete',
          type: 'confirmation'
        }
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          this.service.deleteJob([element.id]).then(() => {
            this.getAllConsultants(this.currentPage, this.pageSize, this.filters)
          });
        }
      });
    } else if (action == 'new') {
      const dialogRef = this.dialog.open(CreateConsultantsComponent, this.dataService.jobDialogConfig({id: null}));
      dialogRef.beforeClosed().subscribe((res) => {
        if (res) {
          const newDialogRef = this.dialog.open(CreateConsultantsComponent, this.dataService.jobDialogConfig({
            id: res.id,
            consultantId: res.consultantId,
          }));
          newDialogRef.afterClosed().subscribe(() => {
            this.isDataUpdatedService.setUpdated(false);
            this.getAllConsultants(this.currentPage, this.pageSize, this.filters)
          });
        }
      });
    } else if (action == 'edit') {
      const consultEditDialogRef = this.dialog.open(CreateConsultantsComponent, this.dataService.jobDialogConfig({
        id: element.id,
        consultantId: element.consultantId,
        consultantData: element,
      }));
      consultEditDialogRef.afterClosed().subscribe(() => {
        if (this.isDataUpdatedService.getBoolean()) {
          this.getAllConsultants(this.currentPage, this.pageSize, this.filters)
          this.isDataUpdatedService.setUpdated(false);
        }
      });
    }
  }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];
    skills.forEach((e: any, index: number) => {
      if (count < 5) {
        skillsString += e.title;
        if (count < 4 && index < skills.length - 1) {
          skillsString += ' | ';
        }
        count++;
      } else {
        pendingSkills.push(e.title);
        pendingCount++;
      }
    });

    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }

  updateSearch() {
    this.filters.query = this.search
    this.paginator.pageIndex = 0;
    this.getAllConsultants(this.paginator.pageIndex, this.paginator.pageSize, this.filters)
  }

  clearFilter() {
    this.filters = {
      consultantId: '',
      consultantName: '',
      title: '',
      experience: [],
      vendorName: '',
      skills: [],
      query: '',
      statuses: [],
      locations: [],
      spocIds: [],
      dateSearchCriteria: {
        filterType: 'CREATED',
        from: '',
        to: ''
      },
      featured: false,
      searchEnable: true,
      active: true,
    };
    this.resetFilters.emitChange(true);
    this.getAllConsultants(0, this.pageSize, this.filters);
  }

  formatLocationData(value: any) {
    return value.map((res: any) => res.name)
  }

  onFiltersChanged(updatedFilters: any) {
    this.filters = updatedFilters;
    this.paginator.pageIndex = 0;
    const fromDate = this.filters.dateSearchCriteria.from;
    const toDate = this.filters.dateSearchCriteria.to;
    const datesValid = (fromDate && toDate) || (!fromDate && !toDate);
    if (datesValid) {
      this.getAllConsultants(0, this.pageSize, this.filters);
    }
  }

  dateTypeChanged($event: boolean) {
    if ($event === true) {
      this.filters.dateSearchCriteria.filterType = "UPDATED";
    } else if ($event === false) {
      this.filters.dateSearchCriteria.filterType = "CREATED";
    }
    this.getAllConsultants(0, this.pageSize, this.filters);
  }

}
