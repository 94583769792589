<header class="main-header" id="header">
  <div class="sb-nav-fixed">
    <nav class="navbar navbar-light bg-white">
      <div class="d-flex align-items-end">
      <button type="button" (click)="toggleSidebar()"  id="sidebarCollapse" class="btn text-sidebar theme-bg-color me-3">
        <i class="fas fa-align-left"></i>
        <span></span>
      </button>
      <h5 style="color: #5A5A5A">{{'ProTeamMate Portal'}} </h5>
      </div>
      <div class="navbar-nav d-flex">
        <li class="nav-item dropdown header-profile">
          <a class="nav-link me-3">
            <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 15.5V10.3C15.5 10.4 15 10.5 14.5 10.5H14V16.5H4V9.5C4 6.7 6.2 4.5 9 4.5C9.1 3.2 9.7 2.1 10.5 1.2C10.2 0.8 9.6 0.5 9 0.5C7.9 0.5 7 1.4 7 2.5V2.8C4 3.7 2 6.4 2 9.5V15.5L0 17.5V18.5H18V17.5L16 15.5ZM7 19.5C7 20.6 7.9 21.5 9 21.5C10.1 21.5 11 20.6 11 19.5H7ZM18 5C18 6.9 16.4 8.5 14.5 8.5C12.6 8.5 11 6.9 11 5C11 3.1 12.6 1.5 14.5 1.5C16.4 1.5 18 3.1 18 5Z" fill="#808080"/>
            </svg>
          </a>
          <a class="nav-link dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="header-info mx-2 text-end">
              <span class="text-black">{{username}}</span>
              <p class="mb-0">{{getRole(userrole)}}</p>
            </div>
            <div class="circle">
              <span *ngIf="!userImage" class="initials"> {{initials}} </span>
              <img *ngIf="userImage" class="circle bg-white" [src]="userImage">
            </div>
          </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="userProfile">
              <div><i class="fas fa-user mx-2"></i>Profile</div>
            </a></li>
            <li><a class="dropdown-item" routerLink="twoFA">
              <div><i class="fas fa-lock mx-2"></i>2 FA</div>
            </a></li>
            <li><a class="dropdown-item" routerLink="changePassword">
              <div><i class="fas fa-lock mx-2"></i>Change Password</div>
            </a></li>
            <li><a class="dropdown-item" (click)="logout()">
              <div><i class="fas fa-sign-out-alt mx-2"></i>Logout</div>
            </a></li>
          </ul>
        </li>
      </div>
    </nav>
  </div>
</header>
