<div class="content-wrapper">
  <div class="content-wrapper-main-inner-content ms-3 me-3">
    <h6 class="pb-1" style="font-weight:500; font-size: 20px">Communication</h6>
    <form [formGroup]="notesForm">
      <div class="d-flex align-items-center justify-content-between">
        <div class="flex-grow-1">
          <mat-form-field appearance="outline" class="w-100">
            <input formControlName="description" matInput placeholder="Type here">
          </mat-form-field>
        </div>
        <div class="ms-2 d-flex align-items-center justify-content-center pb-3">
          <app-button [width]="'150px'" [height]="'40px'" type="primary" (buttonClick)="onSubmit()" [isLoading]="loading">
            Save
          </app-button>
        </div>
      </div>

      <div class="ps-3 pe-3 pb-2 pt-2 note-container" *ngFor="let note of notesData">
        <div class="d-flex align-items-center justify-content-between" style="height: 20px;">
          <div class="d-flex align-items-center justify-content-center">
            <img style="border-radius: 50%; width:24px; height: 24px"
                 [src]="'../../../../../../assets/images/maleDefault.jpg'" alt="logo"/>
            <span class="ps-2 createdBy">{{ note.createdBy }}</span>
          </div>
          <div class="hide-icons">
            <div *ngIf="editableNoteId !== note.id">
            <span class="badge text-white cursor-p" (click)="editNotes(note)">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5 22C4.45 22 3.979 21.8043 3.587 21.413C3.19567 21.021 3 20.55 3 20V6C3 5.45 3.19567 4.97933 3.587 4.588C3.979 4.196 4.45 4 5 4H6V2H8V4H16V2H18V4H19C19.55 4 20.021 4.196 20.413 4.588C20.8043 4.97933 21 5.45 21 6V12H19V10H5V20H12V22H5ZM22.125 17L20 14.875L20.725 14.15C20.9083 13.9667 21.1417 13.875 21.425 13.875C21.7083 13.875 21.9417 13.9667 22.125 14.15L22.85 14.875C23.0333 15.0583 23.125 15.2917 23.125 15.575C23.125 15.8583 23.0333 16.0917 22.85 16.275L22.125 17ZM14 23V20.875L19.3 15.575L21.425 17.7L16.125 23H14Z"
                  fill="#545454"/>
              </svg>
            </span>
            </div>
            <div *ngIf="editableNoteId === note.id">
              <button *ngIf="!loading" type="button"
                      (click)="onUpdateNote(this.webCandidateId,note.id,{description:this.notesForm?.value?.editDescription});"
                      class="btn btn-success btn-sm">Done
              </button>
              <div *ngIf="loading">
                <button class="btn btn-save theme-btn-color" disabled type="button">
                  <div class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only"></span>
                  </div>
                  Done
                </button>
              </div>
            </div>
            <div>
            <span class="badge text-white cursor-p" (click)="onDeleteNote(this.webCandidateId,note.id)">
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.37 8.91003L19.37 10.64L7.24 3.64003L8.24 1.91003L11.28 3.66003L12.64 3.29003L16.97 5.79003L17.34 7.16003L20.37 8.91003ZM6 19V7.00003H11.07L18 11V19C18 19.5305 17.7893 20.0392 17.4142 20.4142C17.0391 20.7893 16.5304 21 16 21H8C7.46957 21 6.96086 20.7893 6.58579 20.4142C6.21071 20.0392 6 19.5305 6 19Z"
                  fill="#ED0F0F"/>
              </svg>
            </span>
            </div>
          </div>
        </div>
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="w-75">
            <div *ngIf="editableNoteId !== note.id">{{ note?.description ?? '--' }}</div>
            <div *ngIf="editableNoteId === note.id">
              <mat-form-field class="w-100">
                <textarea matInput formControlName="editDescription" class="form-control"
                          placeholder="Notes"
                          style="height: 120px;" id="textareaNotes"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div style="font-size: 14px" class="pe-2">{{ note.createdAt | date:'h:mm a, MMM d, yyyy' }}</div>
        </div>
      </div>
    </form>
  </div>
</div>
