import { NgModule } from '@angular/core';
import { RouterModule, Routes } from "@angular/router";
import { AdminVendorsComponent } from "./admin/admin-vendors/admin-vendors.component";
import { LayoutComponent } from './layout/layout.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ManageProfileComponent } from "./admin/manage-profile/manage-profile.component";
import { ManageMembersComponent } from "./admin/manage-members/manage-members.component";
import { UserProfileComponent } from "./admin/user-profile/user-profile.component";
import { CreatePartnerComponent } from "./admin/create-partner/create-partner.component";
import { ChangePasswordComponent } from "./admin/user-profile/change-password/change-password.component";
import { SettingsComponent } from "./admin/settings/settings.component";
import {DashboardComponent} from "./admin/dashboard/dashboard.component";
import {AuditReportComponent} from "./admin/audit-report/audit-report.component";
import {JobsComponent} from "./admin/jobs/jobs.component";
import {ManageConsultantsComponent} from "./admin/manage-consultants/manage-consultants.component";
import {ManageCustomersComponent} from "./admin/manage-customers/manage-customers.component";
import {MyTasksComponent} from "./admin/my-tasks/my-tasks.component";
import {CustomersContactsComponent} from "./admin/customer-contacts/customers-contacts.component";
import {TaskDetailComponent} from "./admin/my-tasks/task-detail/task-detail.component";
import {CommonModule} from "@angular/common";
import {ClientCommunicationComponent} from "./admin/my-tasks/client-communication/client-communication.component";
import {MaterialModule} from "../material.module";
import {FormsModule} from "@angular/forms";
import {InternalCommunicationComponent} from "./admin/my-tasks/internal-communication/internal-communication.component";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MyTaskSettingsComponent} from "./admin/my-tasks/my-task-settings/my-task-settings.component";
import {CustomerAnalyticsComponent} from "./admin/customer-analytics/customer-analytics.component";
import {ThemeComponent} from "../client/theme/theme.component";
import {SolutionsComponent} from "./admin/solutions/solutions.component";
import {CreateSolutionComponent} from "./admin/solutions/create-solution/create-solution.component";
import {WebCandidateTableListingComponent} from "./admin/webcandidates/web-candidate-table-listing/web-candidate-table-listing.component";
import {WebCandidatesComponent} from "./admin/webcandidates/web-candidates/web-candidates.component";
import {MemberAnalyticsComponent} from "./admin/member-analytics/member-analytics.component";
import{TwoFaComponent} from "./admin/two-fa/two-fa.component";

const routes: Routes = [
  {
    path: '', component: LayoutComponent, children: [
      { path: '', component: DashboardComponent},
      { path: 'home', component: VendorsComponent },
      { path: 'vendor', component: AdminVendorsComponent },
      { path: 'admin-vendor', component: AdminVendorsComponent },
      { path: 'manageProfile', component: ManageProfileComponent },
      { path: 'manageConsultants', component: ManageConsultantsComponent },
      { path: 'manageMyTasks', component: MyTasksComponent },
      { path: 'manageCustomers', component: ManageCustomersComponent },
      { path: 'manageMembers', component: ManageMembersComponent },
      { path: 'manageMembers', component: ManageMembersComponent },
      { path: 'changePassword', component: ChangePasswordComponent },
      { path: 'userProfile', component: UserProfileComponent },
      { path: 'createPartner', component: CreatePartnerComponent },
      { path: 'audit', component: AuditReportComponent },
      { path: 'settings', component: SettingsComponent },
      { path: 'jobs', component: JobsComponent },
      {path: 'customerContact', component:CustomersContactsComponent},
      {path: 'customer-analytics', component:CustomerAnalyticsComponent},
      {path: 'member-analytics', component:MemberAnalyticsComponent},
      {path: 'themes', component:ThemeComponent},
      {path: 'solutions', component:SolutionsComponent},
      {path: 'create-solutions', component:CreateSolutionComponent},
      {path: 'edit-solution/:solutionId', component:CreateSolutionComponent, data: { scrollPositionRestoration: 'top' }},
      { path: 'web_data', component: WebCandidateTableListingComponent },
      { path: 'web_data/candidate/:id', component: WebCandidatesComponent },
      { path: 'web_data/customer/:id', component: WebCandidatesComponent },
      {path: 'twoFA', component: TwoFaComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), CommonModule, MaterialModule, FormsModule, MatOptionModule, MatSelectModule, MatInputModule],
  declarations: [
    TaskDetailComponent,
    ClientCommunicationComponent,
    InternalCommunicationComponent,
    MyTaskSettingsComponent,
  ],
  exports: [RouterModule, TaskDetailComponent, ClientCommunicationComponent, InternalCommunicationComponent, MyTaskSettingsComponent]
})
export class ContentRoutingModule { }
