<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Vendors</h5>
        <div class="page-title-right me-3">
          <a routerlink="add-vendor" class="btn btn-create theme-btn-color" (click)="openDialog('new',null)"> Create
            Vendor
            <i class="fas fa-plus fa-xs"></i></a>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="dataTables_filter">
            <label class="w-100 search-input">
              <input [(ngModel)]="search" (ngModelChange)="updateSearch()" class="form-control" placeholder="Search for vendor’s name"
                     type="search"
                     aria-controls="data-table">
              <button class="search-button" (click)="loadVendors(0,this.pageSize,this.filters)" type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>
        <div class="col-md-8 d-flex justify-content-end">
          <mat-slide-toggle
            class="example-margin m-1 p-1"
            color="primary"
            [disabled]=false
            [checked]="showArchived"
            (change)="toggleActiveVendors($event)">
            <div>Show In-Active</div>
          </mat-slide-toggle>
          <div class="ms-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
        </div>
      </div>

<!--      <div *ngIf="showLoader" class=" d-flex justify-content-center align-item-center mt-5 pt-5">-->
<!--        <mat-spinner diameter="50"></mat-spinner>-->
<!--      </div>-->
<!--      <div class="row" *ngIf="!showLoader">-->

      <div class="row">
        <div class="table-responsive col-12 col-sm-12 col-lg-12">
          <table class="table table-striped">
            <thead class="tableview theme-bg-color">
            <tr>
              <th style="cursor: pointer; width: 13%" (click)="toggleSort('vendorName')">Vendor Name
                <i *ngIf="getSort('vendorName').direction !== 'desc'" class="fas fa-arrow-up"
                  [style.color]="getSort('vendorName').active ? 'black' : 'white'"></i>
                <i *ngIf="getSort('vendorName').direction === 'desc'" class="fas fa-arrow-down"
                  [style.color]="getSort('vendorName').active ? 'black' : 'white'"></i>
              </th>
              <th style="cursor: pointer;width: 13%">Contact No</th>
              <th style="width: 13%">Email ID</th>
              <th style="width: 13%">Skills</th>
              <th style="width: 13%">Location</th>
              <th style="cursor: pointer;width: 13%" (click)="toggleSort('createdDate')">Date
                <i *ngIf="getSort('createdDate').direction==='asc' && getSort('createdDate').active"
                   class="fas fa-arrow-up"></i>
                <i *ngIf="getSort('createdDate').direction==='desc' && getSort('createdDate').active"
                   class="fas fa-arrow-down"></i>
              </th>
              <th>Assigned SPOC</th>
            </tr>
            </thead>
            <tbody>

            <tr search-filter  [searchFields]="searchFieldName" [filters]="filters"
                (filtersChanged)="onFiltersChanged($event)" (dateTypeChanged)="dateTypeChanged($event)"
                (resetFilters)="clearFilter()"
            ></tr>

            <ng-container *ngFor="let vendor of vendors ">
              <tr>
                <td>
                  <span style="cursor: pointer" class="theme-text-color fw-bold" title="Edit"
                        (click)="openDialog('edit',vendor)">
                    {{ vendor.vendorName }}
                  </span>
                </td>
                <td>{{ vendor.mobileNumber ? vendor.mobileNumber : '--' }}</td>
                <td>{{ vendor.emailAddress ? vendor.emailAddress : '--' }}</td>
                <td style="width: 13%">
                  <div *ngIf="vendor.skills!=null">
                    <div>
                      {{ this.getSkills((vendor.skills))['skillsString'] }}
                      <span
                        matTooltip="{{ (this.getSkills(vendor.skills)).pendingSkills.length > 0
                                      ? (this.getSkills(vendor.skills)).pendingSkills.join(', ')
                                      : (this.getSkills(vendor.skills)).skillsString }}"
                        matTooltipPosition="before"
                        matTooltipClass="custom-tooltip"
                        *ngIf="(this.getSkills(vendor.skills)).pendingSkillsCount > 0"
                        class="badge badge-pill badge-light">
                      <i class="fas fa-plus fa-xs"></i>
                      <span class="pt-1" style="font-size: 11px">
                        {{ (this.getSkills(vendor.skills)).pendingSkillsCount }}
                      </span>
                    </span>
                    </div>
                  </div>
                  <div *ngIf="vendor.skills?.length==0">
                    --
                  </div>
                </td>
                <td style="text-transform: capitalize;">

                  {{ (vendor.address.city?.name == "" || vendor.address.city?.name == null) ? "" : vendor.address.city?.name + ',' }}
                  {{ (vendor.address.state?.name == "" || vendor.address.state?.name == null) ? "" : vendor.address.state?.name + ', ' }}
                  {{ (vendor.address.country?.name == "" || vendor.address.country?.name == null) ? "--" : vendor.address.country?.name }}
                </td>
                <td>
                  <span class="p-0 m-0" style="font-size: 13px;">
                    Created on: {{ vendor.createdDate | date: 'd MMM y hh:mm a' }}<br>
                    Updated on: {{ vendor.modifiedDate | date: 'd MMM y hh:mm a'}}
                  </span>
                </td>
                <td>{{vendor?.spoc?.firstName ?? '--'}} {{vendor?.spoc?.lastName ?? ''}}</td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <div *ngIf="vendors.length == 0" class="d-flex justify-content-center mt-8">
            <div *ngIf="!this.showLoader">
              <app-not-found-result></app-not-found-result>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive col-12 col-sm-12 col-lg-12">
    <mat-paginator [length]="totalVendors"
                   [pageSize]="pageSize"
                   [pageSizeOptions]="[10,12,25,100]"
                   [hidden]="totalVendors==0"
                   aria-label="Select page">
    </mat-paginator>
  </div>
</div>
