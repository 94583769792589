<div class="content-wrapper">
  <div class="row mb-3">
    <div class="col-md-12">
      <div class="page-title-box d-sm-flex align-items-center justify-content-between">
        <h5 class="page-title">Manage Consultants </h5>
        <div class="d-flex">
          <div class="me-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="downloadReport()">
              <i class="fas fa-cloud-download-alt"></i> Download Report
            </button>
          </div>
          <div class="page-title-right me-3">
            <a (click)="openDialogs('new',null)" class="btn btn-create theme-btn-color"> <i
              class="fas fa-plus fa-xs me-2"></i>
              Create New Consultant
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="content-wrapper-main-inner-content">
      <div class="row mb-2">
        <div class="col-md-4">
          <div id="data-table_filter" class="d-flex justify-content-end dataTables_filter">
            <label class="w-100 search-input">
              <input [(ngModel)]="search" class="form-control" placeholder="Search Customers"
                     (ngModelChange)="updateSearch()" type="search"
                     aria-controls="data-table">
              <button class="search-button"
                      (click)="this.getAllConsultants(this.currentPage,this.pageSize, this.filters)"
                      type='button'>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M19.7479 18.5593L14.0096 12.8169C15.1409 11.4602 15.8213 9.72641 15.8213 7.83277C15.8213 3.51266 12.2758 0 7.91475 0C3.55364 0 0 3.51675 0 7.83687C0 12.157 3.54544 15.6696 7.90655 15.6696C9.7592 15.6696 11.4643 15.0343 12.8169 13.9727L18.5757 19.7315C18.9118 20.0676 19.4118 20.0676 19.7479 19.7315C20.084 19.3954 20.084 18.8954 19.7479 18.5593ZM1.6805 7.83687C1.6805 4.44308 4.47587 1.6846 7.90655 1.6846C11.3372 1.6846 14.1326 4.44308 14.1326 7.83687C14.1326 11.2307 11.3372 13.9891 7.90655 13.9891C4.47587 13.9891 1.6805 11.2266 1.6805 7.83687Z"
                    fill="#808080"/>
                </svg>
              </button>
            </label>
          </div>
        </div>

        <div class="col-md-8 entry-label d-flex justify-content-end align-items-center">
        <span class="mx-4">
          <mat-slide-toggle
            class="example-margin "
            color="primary"
            [disabled]=false
            [checked]="showFeatured ?? undefined"
            (change)="toggleFeaturedConsultant($event)">
            <div class="ActiveButton">Show Featured </div>
          </mat-slide-toggle>
        </span>
          <span class="mx-4">
          <mat-slide-toggle
            class="example-margin "
            color="primary"
            [disabled]=false
            [checked]="showSearchEnable"
            (change)="toggleSearchEnableConsultant($event)">
          <div class="ActiveButton">Show Search Enable</div>
        </mat-slide-toggle>
        </span>
          <mat-slide-toggle
            class="example-margin"
            color="primary"
            [disabled]=false
            [checked]="showActive"
            (change)="toggleActiveConsultant($event)">
            <div class="ActiveButton">Show Active</div>
          </mat-slide-toggle>
          <div class="ms-3 p-0">
            <button class="btn btn-create theme-btn-color" (click)="clearFilter()">
              <i class="fas fa-redo me-2"></i>
              Reset Filters
            </button>
          </div>
        </div>
      </div>
      <!--      <div *ngIf="showLoader" class=" d-flex justify-content-center align-item-center mt-5 pt-5">-->
      <!--        <mat-spinner diameter="50"></mat-spinner>-->
      <!--      </div>-->
      <!--      <div *ngIf="!showLoader">-->
      <div>
        <table class="table table-responsive table-striped">
          <thead class="tableview theme-bg-color">
          <tr>
            <th>Consultant ID</th>
            <th>Consultant Name</th>
            <th>Title</th>
            <th>Experience</th>
            <th>Vendor Name</th>
            <th>Primary Skills</th>
            <th>Consultant Status</th>
            <th>Location Preference</th>
            <th>Assigned SPOC</th>
            <th>Date</th>
          </tr>
          </thead>
          <tbody>
          <tr search-filter [searchFields]="searchFields" [filters]="filters"
              (filtersChanged)="onFiltersChanged($event)"
              (dateTypeChanged)="dateTypeChanged($event)" (resetFilters)="clearFilter()"
          ></tr>
          <ng-container *ngFor="let consultant of consultants">
            <tr>
              <td style="width: 13%">
                <span style="cursor: pointer" class="theme-text-color fw-bold cursor-p" title="Edit "
                      (click)="openDialogs('edit',consultant)">{{ consultant.consultantId }}
              </span>
              </td>
              <td
                style="width: 13%">{{ consultant.firstName + ' ' + (consultant?.middleName == null ? '' : consultant?.middleName) + ' ' + (consultant.lastName == null ? '' : consultant.lastName) }}
              </td>
              <!--              <td style="width: 13%">{{ consultant.title != '' ? consultant.title ?? '&#45;&#45;' : '&#45;&#45;' }}</td>-->
              <td style="width:13%">
                {{ consultant.title ? consultant.title : '' }}
              </td>
              <td style="width: 13%">
                {{
                  (consultant.experienceYear || consultant.experienceMonth) ?
                    ((consultant.experienceYear != 0) ? (consultant.experienceYear + ' yrs') : '' || '') + " " + (((consultant.experienceMonth != 0) ? (consultant.experienceMonth + ' months') : '') || '') :
                    '--'
                }}
              </td>
              <td style="width: 13%">
                {{ consultant.vendor != null ? consultant.vendor.vendorName ?? '--' : '--' }}
              </td>

              <td style="width: 13%">
                <div *ngIf="consultant.primarySkills!=null">
                  <div>
                    {{ this.getSkills((consultant.primarySkills.concat(consultant.secondarySkills ?? [])))['skillsString'] }}
                    <span
                      matTooltip="{{ (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.length > 0
                                      ? (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkills.join(', ')
                                      : (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).skillsString }}"
                      matTooltipPosition="before"
                      matTooltipClass="custom-tooltip"
                      *ngIf="(this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkillsCount > 0"
                      class="badge badge-pill badge-light">

                      <i class="fas fa-plus fa-xs"></i>
                      <span class="pt-1" style="font-size: 11px">
                        {{ (this.getSkills(consultant.primarySkills.concat(consultant.secondarySkills ?? []))).pendingSkillsCount }}
                      </span>
                    </span>
                  </div>
                </div>
                <div *ngIf="consultant.primarySkills?.length==0 && consultant.secondarySkills?.length==0 ">
                  --
                </div>
              </td>
              <td style="width: 10%">
                <label class="switch"
                       style="color: #14804A; font-weight: 500;background-color: #E1FCEF">{{ consultant.status ?? '--' }}</label>
              </td>
              <td>
                <div class="d-flex flex-row" matTooltip="{{formatLocationData(consultant?.preferredLocations)}}"
                     matTooltipPosition="before"
                     matTooltipClass="custom-tooltip"
                >
                  <div *ngIf="consultant.jobType" class="p-0 m-0"> {{ consultant.jobType }}</div>
                  <div *ngIf="consultant.preferredLocations.length > 0 && consultant.jobType"
                       class="me-1 ms-1">{{ '|' }}
                  </div>
                  <div *ngIf="consultant.preferredLocations.length > 0 && consultant.jobType"
                       class="p-0 m-0 d-flex flex-row">
                    {{
                      consultant.preferredLocations[0].name.length > 8 ? consultant.preferredLocations[0].name.substring(0, 8) : consultant.preferredLocations[0].name
                    }}
                    <div
                      *ngIf="consultant.preferredLocations.length > 1 || consultant.preferredLocations[0].name.length > 8">
                      {{
                        '...'
                      }}
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ consultant.spoc?.firstName }} {{ consultant.spoc?.lastName }}</td>
              <td style="width: 13%">
                <div class="d-flex flex-column">
                  <span *ngIf="consultant.updatedDate" style="font-size: 13px;">
                  Updated On {{ consultant.updatedDate | date: 'dd MMM y hh:mm a' }}
                </span>
                  <span class="p-0 m-0" *ngIf="consultant.createdDate" style="font-size: 13px;">
                      Created On {{ consultant.createdDate | date: 'dd MMM y hh:mm a' }}
                </span>
                </div>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
      <div *ngIf="!showLoader && consultants.length == 0">
       <app-not-found-result></app-not-found-result>
      </div>
      <mat-paginator [length]="totalEmployee"
                     [pageSize]="pageSize"
                     [pageSizeOptions]="[10,12,25,50,100]"
                     [hidden]="totalEmployee == 0"
                     aria-label="Select page">
      </mat-paginator>
    </div>
  </div>
</div>
