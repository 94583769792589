import {AfterViewInit, Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {Candidate, CandidateSkills} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {MatAutocompleteTrigger} from "@angular/material/autocomplete";
import {MatDialog} from "@angular/material/dialog";
import {ApiService} from "../../../../services/api.service";
import {Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {map, startWith} from "rxjs/operators";

@Component({
  selector: 'app-skills-component',
  templateUrl: './skills-component.component.html',
  styleUrls: ['./skills-component.component.css']
})

export class SkillsComponentComponent implements OnInit, AfterViewInit {
  @Input() customerInfoData: Candidate;
  skillControl = new FormControl('');
  selectedSkill: CandidateSkills[] = [];
  skillName = '';
  filteredSkills: Observable<CandidateSkills[]>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  skillsRes: CandidateSkills[] = [];
  showloader = false;
  role: string | null;
  @ViewChild('skillsAutoCompleter', {read: MatAutocompleteTrigger})
  skillsAutoCompleter: MatAutocompleteTrigger;
  loading: boolean = false
  @Input() handleSkillUpdate!: (jobCandidateId: number, payload: any) => Observable<any>;
  selectedLevel: string = 'MASTER';
  showDropdown: boolean = false;
  @ViewChild('dropdown') dropdown: ElementRef;
  @ViewChild('skillInput') skillInput: ElementRef;
  skillLevels = [
    {id: 'MASTER', label: 'Master'},
    {id: 'INTERMEDIATE', label: 'Intermediate'},
    {id: 'BEGINNER', label: 'Beginner'}
  ];

  constructor(
    private service: ApiService,
    public dialog: MatDialog,
    public router: Router,
    public snackBar: MatSnackBar,
    private isVendorsDataUpdated: IsDataUpdatedService,
  ) {
  }

  ngOnInit(): void {
    this.getSkillType();
    this.role = localStorage.getItem('role');
  }

  ngAfterViewInit() {
    this.getSkillType();
    setTimeout(() => {
      if (this.customerInfoData && this.customerInfoData.candidateSkills) {
        this.selectedSkill = this.customerInfoData.candidateSkills.map(skill => ({
          ...skill,
          level: skill.skillType
        }));

      }
    }, 1000);
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const clickedInside = this.skillInput?.nativeElement?.contains(event.target) || this.dropdown?.nativeElement?.contains(event.target);
    if (!clickedInside) {
      this.showDropdown = false;
    }
  }

  removeSkill(event: any, skill: CandidateSkills): void {
    event.preventDefault();
    event.stopPropagation();
    const index = this.selectedSkill.findIndex(s => s.skill.id === skill.skill.id);
    if (index >= 0) {
      this.selectedSkill.splice(index, 1);
      this.skillsRes.push(skill);
      this.refreshFilteredSkills();
    } else {
      console.log('Skill not found in selectedSkill:', skill);
    }
  }

  selectSkills(skill: CandidateSkills): void {
    const skillExists = this.selectedSkill.find(s => s.id === skill.id);
    if (!skillExists) {
      const skillWithLevel = {skill, skillType: this.selectedLevel} as CandidateSkills;
      this.selectedSkill.push(skillWithLevel);
      this.skillsRes = this.skillsRes.filter(s => s.id !== skill.id);
      this.refreshFilteredSkills();
    }
  }

  private refreshFilteredSkills(): void {
    this.filteredSkills = this.skillControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterSkills(value))
    );
    this._filterSkills(this.skillControl.value);
  }

  getLevelColor(level: string): string {
    const levelColors: { [key: string]: string } = {
      MASTER: '#22C55E',
      INTERMEDIATE: '#EAB308',
      BEGINNER: '#EF4444',
    };
    return levelColors[level] || '#ccc';
  }

  addSkillType(): void {
    if (this.skillName === "") {
      return;
    } else {
      let payload = {
        'title': this.skillName,
      };
      this.service.addOrgSkills(payload).then(res => {
        this.isVendorsDataUpdated.setUpdated(true);
        this.getSkillType();
        const skillWithLevel = { skill: res, skillType: this.selectedLevel } as CandidateSkills;
        this.selectedSkill.push(skillWithLevel);
        this.skillName = '';
        this.skillControl.setValue('');
      }, (e) => {
        console.log('error', e.error.message);
      });
    }
  }

  addSkill(): void {
    this.loading = true;
    const skillRequests = this.selectedSkill.map(skill => ({
      skillId: skill.skill.id,
      skillType: skill.skillType,
    }));
    const payload = {
      skillRequests: skillRequests
    };
    this.handleSkillUpdate(this.customerInfoData.id, payload).subscribe(
      (res: any) => {
        this.loading = false;
        this.skillName = '';
        this.showloader = false;
        this.getSkillType();
      },
      (error: any) => {
        console.log('Error:', error.message);
        this.loading = false;
      }
    );
  }

  getSkillType(): void {
    this.service.getSkills().then((res: any[]) => {
      this.skillsRes = res.filter((skill) => skill.title !== "");
      this.filteredSkills = this.skillControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterSkills(value))
      );
    });
  }

  private _filterSkills(value: string): CandidateSkills[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : '';
    const selectedSkillIds = this.selectedSkill.map(skill => skill.skill.id);
    return this.skillsRes.filter(skill =>
      skill?.title?.toLowerCase().includes(filterValue) &&
      !selectedSkillIds.includes(skill.id ?? 0)
    );
  }
}
