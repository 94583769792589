import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../../services/api.service";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-two-fa',
  templateUrl: './two-fa.component.html',
  styleUrls: ['./two-fa.component.css']
})
export class TwoFaComponent implements OnInit {
  email :string = '';
  TwoFaEnabled : boolean;
  selectedAuthMethod : string = 'none';

  constructor(
    private service:ApiService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
     this.isTwoFaEnable()
    }

    isTwoFaEnable(){
      this.service.getLoginUser().subscribe(res => {
        this.email = res.email;
        this.TwoFaEnabled = !!res.isTwoFactorEnabled;
        this.selectedAuthMethod = this.TwoFaEnabled ? 'email' : 'none';
      });
    }

  twoFactorAuthentication(){
    if(this.selectedAuthMethod === 'email'){
      this.service.enableTwoFactorAuthentication().subscribe(
        res => {
          this.TwoFaEnabled = true;
          this.toaster.success("2-FA enabled");
        }
      );
    }else if(this.selectedAuthMethod === 'none'){
      this.service.disableTwoFactorAuthentication().subscribe(res => {
        this.TwoFaEnabled = false;
        this.toaster.success("2-FA disabled")
        console.log(this.toaster)
      })
    }
  }

  formatEmail(email:string) : string{
    if(!email || !email.includes('@')){
      return email;
    }else{
      return email.substring(0,4) + '*****' + email.substring(email.lastIndexOf('@')-3);
    }
  }

}
