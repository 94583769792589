import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {finalize, tap} from 'rxjs/operators';
import {DataService} from "./data.service";
import {UtilityService} from "../shared/utility.service";

@Injectable({
  providedIn: 'root'
})
export class CustomInterceptorService implements HttpInterceptor {

  constructor(private router: Router,
              private utility: UtilityService,
              private data: DataService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (localStorage.getItem('access_token')) {
      if (request.url.match('token')) {
        return next.handle(request);
      }
      let orgId = localStorage.getItem('orgId');
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'org-id': orgId ? orgId.toString() : ''
        }
      });
      return next.handle(request).pipe(tap(() => {
          },
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status !== 401) {
                return;
              }
              console.log(localStorage);
              window.location.reload();
              const loginType = localStorage.getItem('loginType');
              if (loginType === 'Client') {
                this.utility.logoutUser();
                this.router.navigate(['/login']);
              } else if (loginType === 'Vendor') {
                this.utility.logoutMember();
                this.router.navigate(['/admin/login'])
              }
              localStorage.clear();
            }
          }),
        finalize(
          () => {
            this.data.isLoading.next(false);
          }
        )
      );
    } else {
      return next.handle(request).pipe(
        finalize(
          () => {
            this.data.isLoading.next(false);
          }
        ));
    }
  }
}

