import {Component, OnInit, ViewChild} from '@angular/core';
import {Candidate, CustomerWeb} from "../../../../shared/interfaces";
import {MatPaginator, PageEvent} from "@angular/material/paginator";
import {ApiService} from "../../../../services/api.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgDialogAnimationService} from "ng-dialog-animation";
import {debounce} from "lodash";
import {ResetFiltersService} from "../../../../services/resetFilters.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-web-candidate-table-listing',
  templateUrl: './web-candidate-table-listing.component.html',
  styleUrls: ['./web-candidate-table-listing.component.css']
})
export class WebCandidateTableListingComponent implements OnInit {
  tableHeader: string[] = ['Candidate Name', 'Job Title', 'Experience', 'Skills', 'Location Preference', 'Notice(Days)', 'Status', 'Date'];

  searchFieldName: string[] = ['candidateName', 'title', 'ExperienceYear', 'skills', 'locationsPref', 'noticePeriod', 'statusWebCustomer', 'dateSearchCriteria',];

  searchFieldNameForCustomer: string[] = ['contactName', 'companyName', 'email', 'mobile', 'verificationStatusRequests', 'location', 'dateSearchCriteria',];

  filters = {
    dateSearchCriteria: {
      filterType: 'CREATED',
      from: '',
      to: '',
    },
    locationPreference: [],
    noticePeriod: [],
    status: [],
    skills: [],
    candidateName: '',
    query: '',
    title: '',
  };

  customerFilters = {
    query: '',
    contactName: '',
    companyName: '',
    email: '',
    emailSentStatus: '',
    mobile: '',
    verificationStatusRequests:[],
    location:'',
    dateSearchCriteria: {
      filterType: 'CREATED',
      from: '',
      to: '',
    },
  };
  candidates: Candidate[] = [];
  customers: CustomerWeb[] = [];
  currentPage = 0;
  showLoader: boolean = true;
  search = '';
  customerSearch = '';
  pageSize = 12;
  totalEmployee = 0;
  totalCustomer = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  currentTabIndex: number = 0;
  selected = 0
  constructor(
    private service: ApiService,
    public snackBar: MatSnackBar,
    public dialog: NgDialogAnimationService,
    private resetFilters: ResetFiltersService,
    private route: ActivatedRoute,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const tabIndex = Number(params['tab']) || 0;
      this.selected = tabIndex;
      this.currentTabIndex = tabIndex;
      if (tabIndex === 0) {
        this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
      } else if (tabIndex === 1) {
        this.getAllWebCustomers(this.currentPage, this.pageSize, [], this.customerFilters);
      }
    });
    this.getAllWebCandidates = debounce<any>(this.getAllWebCandidates, 600);
  }

  onFiltersChanged(updatedFilters: any) {
    const filteredFilters = {...updatedFilters};
    delete filteredFilters.locationsPref;
    this.filters = {
      ...filteredFilters,
      query: this.search,
      locationPreference: updatedFilters?.locationsPref ?? []
    };
    this.paginator.pageIndex = 0;
    this.filters.query = this.search;
    this.getAllWebCandidates(0, this.pageSize, [], this.filters);
  }

  onFiltersChangedCustomer(updatedFilters: any) {
    const filteredFilters = {...updatedFilters};
    delete filteredFilters.locationsPref;
    this.customerFilters = {
      ...filteredFilters,
      query: this.customerSearch,
      locationPreference: updatedFilters?.locationsPref ?? []
    };
    this.paginator.pageIndex = 0;
    this.customerFilters.query = this.customerSearch;
    this.getAllWebCustomers(0, this.pageSize, [], this.customerFilters);
  }

  onPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
  }

  onCustomerPageChange(event: PageEvent) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getAllWebCustomers(this.currentPage, this.pageSize, [], this.customerFilters);
  }

  updateSearch() {
    this.filters.query = this.search;
    this.paginator.pageIndex = 0;
    this.getAllWebCandidates(this.currentPage, this.pageSize, [], this.filters);
  }

  updateCustomerSearch() {
    this.customerFilters.query = this.customerSearch;
    this.paginator.pageIndex = 0;
    this.getAllWebCustomers(this.currentPage, this.pageSize, [], this.customerFilters);
  }

  getAllWebCandidates(page: number, size: number, sort: string[], payload: any): void {
    this.showLoader = true;
    this.service.getWebCandidate(page, size, sort, payload).subscribe({
      next: res => {
        this.candidates = res.content;
        this.paginator.pageIndex = res.page.number;
        this.paginator.pageSize = res.page.size;
        this.pageSize = res.page.size;
        this.totalEmployee = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  getAllWebCustomers(page: number, size: number, sort: string[], payload: any): void {
    this.showLoader = true;
    this.service.getWebCustomer(page, size, sort, payload).subscribe({
      next: res => {
        this.customers = res.content;
        this.paginator.pageIndex = res.page.number;
        this.paginator.CustomerpageSize = res.page.size;
        this.pageSize = 12;
        this.totalCustomer = res.page.totalElements;
        this.showLoader = false;
      },
      error: err => {
        console.log('error', err);
        this.showLoader = false;
      },
      complete: () => {
        this.showLoader = false;
      }
    });
  }

  getSkills(skills: any): { skillsString: string, pendingSkillsCount: number, pendingSkills: string[] } {
    let skillsString = '';
    let count = 0;
    let pendingCount = 0;
    let pendingSkills: string[] = [];
    skills?.forEach((skill: any, index: number) => {
      if (count < 5) {
        skillsString += skill.title;
        if (count < 4 && index < skills.length - 1) {
          skillsString += ' | ';
        }
        count++;
      } else {
        pendingSkills.push(skill.title);
        pendingCount++;
      }
    });
    return {
      skillsString: skillsString,
      pendingSkillsCount: pendingCount,
      pendingSkills: pendingSkills
    };
  }

  clearFilter() {
    this.filters = {
      dateSearchCriteria: {
        filterType: 'CREATED',
        from: '',
        to: '',
      },
      locationPreference: [],
      noticePeriod: [],
      status: [],
      skills: [],
      candidateName: '',
      query: '',
      title: '',
    };
    this.resetFilters.emitChange(true);
    this.getAllWebCandidates(0, this.pageSize, [], this.filters);
  }

  formatLocationData(data: any) {
    if (Array.isArray(data)) {
      return data.map(item => item.location);
    }
    return [];
  }

  tabOnChange(tabIndex: number) : void {
    this.currentPage = 0;
    this.selected = tabIndex;
    this.currentTabIndex = tabIndex;
    this.router.navigate([], {
      queryParams: { tab: tabIndex },
      queryParamsHandling: 'merge',
    });
    if (tabIndex === 0) {
      this.getAllWebCandidates(0, this.pageSize, [], this.filters);
    } else if (tabIndex === 1) {
      this.getAllWebCustomers(this.currentPage, this.pageSize, [], this.customerFilters);
    }
  }
}
