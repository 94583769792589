import { Injectable } from '@angular/core';
import {SessionMap} from "../../shared/interfaces";
import {Common} from "../../shared/Common";
import {DataService} from "../../services/data.service";
import {ThemeLoaderService} from "../../services/theme-loader.service";
import {ApiService} from "../../services/api.service";
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  showLoader = false;

  constructor(
    private service: ApiService,
    private dataService: DataService,
    private themeLoaderService: ThemeLoaderService,
    private router: Router
  ) { }

  userLogin(res : any) : void{
    localStorage.setItem('access_token', res.access_token);
    localStorage.setItem('orgId', res.org_id);
    localStorage.setItem('name', res.name);
    localStorage.setItem('userId', res.user_id);
    localStorage.setItem('role', res.role);
    const rootVariable = document.documentElement;
    if (res.session_id) {
      let currentSessionMap: SessionMap = {
        userEmail: res.user_email,
        sessionId: res.session_id
      };
      if (localStorage.getItem('sessions')) {
        let str = localStorage.getItem('sessions');
        if (str) {
          let sessionMaps: SessionMap[] = JSON.parse(str);
          sessionMaps.push(currentSessionMap);
          localStorage.setItem('sessions', JSON.stringify(sessionMaps));
        }
      } else {
        let sessionMaps: SessionMap[] = [];
        sessionMaps.push(currentSessionMap);
        localStorage.setItem('sessions', JSON.stringify(sessionMaps));
      }
    }
    this.service.getLoginUser().subscribe({
      next: res => {
        const primaryColor = res?.theme?.colour ?? '#76ba991a';
        const secondaryColor = Common.rgbaToHex(Common.hexToRGBA(primaryColor, 0.1));
        const ColorObj = {primaryColor, secondaryColor};
        localStorage.setItem('selectedTheme', JSON.stringify(ColorObj));
        rootVariable.style.setProperty('--primaryColor', primaryColor);
        rootVariable.style.setProperty('--secondaryColor', secondaryColor);
        this.themeLoaderService.setTheme(primaryColor, secondaryColor);
      },
      error: err => {
        console.error('Error fetching user theme:', err);
      },
      complete: () => {
        this.dataService.isLoading.next(false);
      }
    });

    this.showLoader = false;
    this.router.navigate(['/dashboard']);
  }
}
