import {Injectable} from '@angular/core';
import {ApiService} from "../services/api.service";

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(private service: ApiService) {
  }

  logoutMember() {
    this.service.adminLogout().subscribe((res) => {
    }, error => {
      console.log('err', error)
    })
  }

  logoutUser() {
    this.service.clientLogout().then(() => {
    }, error => {
      console.log('err', error)
    })
  }


}
