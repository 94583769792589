<section class="login__wrapper">
  <div class="row">
    <div class="col-lg-6 col-md-12 form-section">
      <div class="logo-container">
        <img src="../../assets/adminLogo.png" alt="Logo" class="logo"/>
      </div>
      <div class = "form-container">
        <div class="form-content">
          <h4 class="heading_2">Verify OTP</h4>
          <p class="subtext">OTP sent to {{ username }}</p>
          <form [formGroup]="otpFormGroup" (submit)="verifyOtp()">
            <div class="mb-3">
              <ng-otp-input [formCtrl]="otp" [config]="{length:6}" (keydown)="allowOnlyDigits($event)" ></ng-otp-input>
            </div>
            <div class="d-flex align-items-center justify-content-start mb-3">
              <div class="text-danger">{{ this.errorMessage }}</div>
            </div>
            <div class="d-flex align-items-center justify-content-center mb-3">
              <div *ngIf="otpSent" class="text-success">{{ this.infoMessage }}</div>
            </div>
            <button *ngIf="!otpLoader" class="btn btn-theme w-100" type="submit">Verify OTP</button>
            <div *ngIf="otpLoader" class="loader-wrapper">
              <button class="btn btn-theme w-100" disabled>
                <div class="spinner-border" role="status"></div>
              </button>
            </div>
            <div *ngIf="!resendLimitExceeded" class="resend-section mt-3">
              <div *ngIf="isResendDisabled" class="timer-text">
                You can resend OTP in {{ timer }} seconds
              </div>
              <button class="btn btn-sm {{ isResendDisabled ? '' : 'text-decoration-underline' }}"  [disabled]="isResendDisabled" (click)="resendOtp()">Resend OTP</button>
            </div>
          </form>
        </div>
      </div>
      <div class="footer-text">
        © 2024 Copyright, <b style="color:#1E293B">Linkage IT</b><sup>®</sup>
      </div>
    </div>
    <div class="col-lg-6 col-md-12 col-sm-12 bg-img">
      <div class="informeson">
        <img src="../../assets/loginBackgroundImage.png" alt="Background Image"/>
      </div>
    </div>
  </div>
</section>
