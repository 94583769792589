import {HostListener, Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {Subject} from "rxjs";
import {debounceTime} from "rxjs/operators";
import {UtilityService} from "../shared/utility.service";

@Injectable({
  providedIn: 'root'
})
export class SessionTimeoutService {

  private idleTimeLimit: number = 30 * 60 * 1000;
  private inactivityTimeout: any;
  private userActivitySubject: Subject<any> = new Subject();

  constructor(private router: Router, private utility: UtilityService) {
    this.resetIdleTime();
    this.userActivitySubject.pipe(debounceTime(500)).subscribe(() => {
      this.resetIdleTime();
    });
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('document:keydown', ['$event'])
  @HostListener('document:click', ['$event'])
  onUserActivity(): void {
    this.userActivitySubject.next();
  }

  private resetIdleTime(): void {
    if (this.inactivityTimeout) {
      clearTimeout(this.inactivityTimeout);
    }

    this.inactivityTimeout = setTimeout(() => {
      this.logout();
    }, this.idleTimeLimit);
  }

  private logout() {
    const loginType = localStorage.getItem('loginType');
    if (loginType === 'Client') {
      this.utility.logoutUser()
      this.router.navigate(['/login']);
    } else if (loginType === 'Vendor') {
      this.utility.logoutMember()
      this.router.navigate(['/admin/login'])
    }
    localStorage.clear();
    sessionStorage.clear();
  }
}
