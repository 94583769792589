<div class="ps-3 pe-3 pt-2">
  <div class="border-bottom d-flex align-items-center justify-content-between">
    <h5>Share this link via</h5>
    <div class="cursor-p mb-2" (click)="closeModal()">
      <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.4818 4.54551C23.3192 3.70813 23.3199 2.35117 22.4834 1.51465C21.6469 0.678132 20.2899 0.678831 19.4525 1.51621L1.51696 19.4518C0.67958 20.2892 0.678882 21.6461 1.5154 22.4827C2.35192 23.3192 3.70888 23.3185 4.54626 22.4811L22.4818 4.54551Z"
          fill="#6D6D6D"/>
        <path
          d="M19.4545 22.4838C20.2919 23.3212 21.6488 23.3219 22.4853 22.4854C23.3219 21.6488 23.3212 20.2919 22.4838 19.4545L4.54821 1.51891C3.71083 0.681533 2.35387 0.680835 1.51735 1.51735C0.680832 2.35387 0.68153 3.71083 1.51891 4.54821L19.4545 22.4838Z"
          fill="#6D6D6D"/>
      </svg>
    </div>
  </div>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-center pt-2">
      <div class='pe-2 cursor-p' (click)="shareOnWhatsApp()">
        <svg width="40" height="4s0" xmlns="http://www.w3.org/2000/svg" aria-label="WhatsApp" role="img"
             viewBox="0 0 512 512" fill="#000000">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <rect width="512" height="512" rx="15%" fill="#25d366"></rect>
            <path fill="#25d366" stroke="#ffffff" stroke-width="26"
                  d="M123 393l14-65a138 138 0 1150 47z"></path>
            <path fill="#ffffff"
                  d="M308 273c-3-2-6-3-9 1l-12 16c-3 2-5 3-9 1-15-8-36-17-54-47-1-4 1-6 3-8l9-14c2-2 1-4 0-6l-12-29c-3-8-6-7-9-7h-8c-2 0-6 1-10 5-22 22-13 53 3 73 3 4 23 40 66 59 32 14 39 12 48 10 11-1 22-10 27-19 1-3 6-16 2-18"></path>
          </g>
        </svg>
      </div>
      <div class="cursor-p" (click)="shareOnLinkedIn()">
        <svg width="52" height="52" viewBox="2 0 15 16" xmlns="http://www.w3.org/2000/svg"
             fill="none">
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
             stroke-linejoin="round"></g>
          <g id="SVGRepo_iconCarrier">
            <path fill="#0A66C2"
                  d="M12.225 12.225h-1.778V9.44c0-.664-.012-1.519-.925-1.519-.926 0-1.068.724-1.068 1.47v2.834H6.676V6.498h1.707v.783h.024c.348-.594.996-.95 1.684-.925 1.802 0 2.135 1.185 2.135 2.728l-.001 3.14zM4.67 5.715a1.037 1.037 0 01-1.032-1.031c0-.566.466-1.032 1.032-1.032.566 0 1.031.466 1.032 1.032 0 .566-.466 1.032-1.032 1.032zm.889 6.51h-1.78V6.498h1.78v5.727zM13.11 2H2.885A.88.88 0 002 2.866v10.268a.88.88 0 00.885.866h10.226a.882.882 0 00.889-.866V2.865a.88.88 0 00-.889-.864z"></path>
          </g>
        </svg>
      </div>
    </div>
    <div class="pt-1">
      <span>Or copy link</span>
      <div class="input-group">
        <input
          class="shareInput"
          type="text"
          [(ngModel)]='copyLink'
          readOnly
        />
        <button (click)="copyToClipboard()" class="theme-bg-color text-white p-1 copyButton">
          {{ buttonText }}
        </button>
      </div>
    </div>
  </div>
</div>
