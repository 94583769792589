import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ApiService} from "../../../../services/api.service";
import {Customer, customerContact, Skill, Skills, TimeZone} from "../../../../shared/interfaces";
import {Observable} from "rxjs/internal/Observable";
import {map, startWith} from "rxjs/operators";
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {ToastrService} from "ngx-toastr";
import {IsDataUpdatedService} from "../../../../services/isDataUpdated.service";
import {QuillEditorComponent} from "ngx-quill";

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.css']
})

export class CreateJobComponent implements OnInit {
  @ViewChild('quillEditor') quillEditor: QuillEditorComponent;
  skillControl = new FormControl();
  jobId: any;
  visible2 = false;
  errorMessage: string | null;
  type: any;
  minimumExperience:any[]=[];
  maximumExperience:any[]=[];
  jobTypes:any[]=[];
  workLocations:any[]=[];
  jobForm: FormGroup;
  customerClients:Customer[]=[]
  selectedCustomerClient!: Customer;
  jobError = '';
  showloader=false;
  addOnBlur = true;
  jobStatus = false;
  jobCreatedBy :boolean = false;
  skillName = '';
  allSkillsRes: any = [];
  skills : any[] = [];
  contactId : string;
  edit:boolean = false;
  clientContactList:customerContact[] = [];
  skillCtrl = new FormControl('');
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  clientControl = new FormControl('');
  filteredSkills: Observable<any[]>;
  exactMatchFound: boolean = false;
  filteredOptions: Observable<Customer[]>;
  @ViewChild("chipGrid") chipGrid: ElementRef;
  errorShow:string;
  @ViewChild('clientNameAutoCompleter', {read: MatAutocompleteTrigger})
  clientNameAutoCompleter: MatAutocompleteTrigger;
  @ViewChild('skillAutoCompleter', {read: MatAutocompleteTrigger})
  skillAutoCompleter: MatAutocompleteTrigger;
  subject: string;
  durations:any[]=[];
  @ViewChild('timeZoneAutoCompleter', {read: MatAutocompleteTrigger})
  timeZoneAutoCompleter: MatAutocompleteTrigger;
  workTimeZoneControl = new FormControl('(GMT +05:30) IST');
  workTimeZones: TimeZone[];
  filteredTimeZones: Observable<TimeZone[]>;
  workTimeZone:any[]=[];
  content: '';

  constructor( @Inject(MAT_DIALOG_DATA) public data: any,
               private fb: FormBuilder,
               private service: ApiService,
               private dialogRef: MatDialogRef<CreateJobComponent>,
               private toaster: ToastrService,
               private isJobsDataUpdated: IsDataUpdatedService,
               ) {
    this.minimumExperience=[0,1,2,3,4,5,6,7,8,9,10];
    this.maximumExperience=[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];
    this.jobTypes=['Permanent','Contract'];
    this.workLocations=['Onsite','Hybrid','Remote'];
    this.durations=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24]
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scrollEvent, true);
    this.jobForm = this.fb.group({
      title: ['', Validators.required],
      description: [''],
      contactId: ['', Validators.required],
      clientName: [''],
      customerContact: [''],
      duration: [1],
      minExperience: ['', Validators.required],
      maxExperience: ['', Validators.required],
      noOfOpenings: ['1', Validators.pattern("[0-9]{1,15}")],
      notes: [''],
      skills: [''],
      active: [false],
      status: [''],
      tentativeStartDate: [''],
      workTimeZone: [''],
      jobTypes: [''],
      workLocations: [''],
    });
    if (this.data.jobId) {
      this.type = 'edit';
      this.service.getJobDetail(this.data.jobId).subscribe(res => {
        this.populateJobForm(res);
        this.setFormControlsDisabled(res.createdByClient);
      });
    }
    this.service.getSkills().then((res: any) => {
      this.allSkillsRes = res;
    }).then(() => {
      this.performFilteration();
    });
    this.getCustomerClients();
    this.getWorkTimeZone();
  }

  private populateJobForm(res: any): void {
    this.jobForm.get('title')?.setValue(res.title);
    this.jobForm.get('description')?.setValue(res.description);
    this.jobForm.get('id')?.setValue(res.id);
    this.clientControl.setValue(res?.customerContact?.customer);
    this.getAllClientContacts(this.clientControl?.value?.id, 'EDIT');
    this.selectedCustomerClient = res.customerContact;
    this.jobForm.get('customerContact')?.setValue(res.customerContact);
    this.jobForm.get('minExperience')?.setValue(res.minExperience);
    this.jobForm.get('maxExperience')?.setValue(res.maxExperience);
    this.jobForm.get('jobTypes')?.setValue(res.jobTypes);
    this.jobForm.get('workLocations')?.setValue(res.workLocations);
    this.jobForm.get('noOfOpenings')?.setValue(res.noOfOpenings);
    this.jobForm.get('notes')?.setValue(res.notes);
    this.jobForm.get('active')?.setValue(res.active);
    this.jobForm.get('duration')?.setValue(res.duration);
    this.jobForm.get('workTimeZone')?.setValue(res.workTimeZone);
    this.workTimeZoneControl.setValue(res.workTimeZone);
    this.skills = res.skills;
    this.jobStatus = res.active;
    this.jobForm.get('contactId')?.setValue(res?.customerContact?.id);
    this.jobCreatedBy = res.createdByClient;
  }
  private setFormControlsDisabled(createdByClient: boolean): void {
    const controlsToDisable = ['title', 'description', 'customerContact', 'minExperience', 'maxExperience',
      'noOfOpenings', 'contactId', 'duration', 'workTimeZone'];
    if(createdByClient) {
      this.workTimeZoneControl.disable();
      this.clientControl.disable();
      this.skillControl.disable();
      controlsToDisable.forEach(control => {
        this.jobForm.get(control)?.disable();
      });
    }
    if(!createdByClient) {
      this.clientControl.disable();
    }
  }
  scrollEvent = (event: any): void => {
    if (this.clientNameAutoCompleter?.panelOpen) {
      this.clientNameAutoCompleter.updatePosition();
    } else if (this.skillAutoCompleter?.panelOpen) {
      this.skillAutoCompleter.updatePosition();
    } else if (this.timeZoneAutoCompleter?.panelOpen) {
      this.timeZoneAutoCompleter.updatePosition();
    }
  };

  compareContacts(contact1: any, contact2: any): boolean {
    return contact1 && contact2 ? contact1.id === contact2.id : contact1 === contact2;
  }

  getCustomerClients(){
    this.service.getActiveCustomerClients().subscribe((response)=>{
      this.customerClients=response;
      // @ts-ignore
      this.filteredOptions = this.clientControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          const name = typeof value === 'string' ? value : value?.name ?? "";
          return name ? this._filter(name as string) : this.customerClients.slice();
        }),
      );
    });
  }

  _filter(name: string): Customer[] {
    const filterValue = name.toLowerCase();
    return this.customerClients.filter((options: Customer) => options.companyName.toLowerCase().includes(filterValue));
  }

  displayDocFn(doc: Customer){
    return (doc && (doc.companyName ||doc.customerId)) ? doc.customerId +' | '+doc.companyName : '';
  }

  performFilteration(): void {
    this.filteredSkills = this.skillCtrl.valueChanges.pipe(
      startWith(''),
      map( value =>
        {
          const name = typeof value === 'string' ? value.trim() : value?.title.trim() ?? "";
          return name ? this._skillFilter(name as string || '') : this.allSkillsRes.slice();
        }
      ),
    );
  }
  private _skillFilter(name: string): Skill[] {
    const filterValue = name.toLowerCase();
    this.exactMatchFound = this.allSkillsRes.some((skill: Skills) => skill.title.toLowerCase() == filterValue);
    return this.allSkillsRes.filter((option: { title: string; id: any; }) =>
      option.title.toLowerCase().includes(filterValue)

    );
  }

  getWorkTimeZone() {
    this.service.getWorkTimeZone().subscribe((res: TimeZone[]) => {
      this.workTimeZones = res;
      this.filteredTimeZones = this.workTimeZoneControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filterTimeZones(value))
      );
    });
  }

  onTimeZoneSelected(event: any) {
    const selectedTimeZone = event.option.value;
    this.jobForm.get('workTimeZone')?.setValue(selectedTimeZone);
  }

  private _filterTimeZones(value: string): TimeZone[] {
    const filterValue = value.toLowerCase();
    return this.workTimeZones.filter(timeZone =>
      timeZone.label.toLowerCase().includes(filterValue) ||
      timeZone.value.toLowerCase().includes(filterValue)
    );
  }

  getSkillType(): void {
    this.service.getSkills().then((res: Skills[]) => {
      this.allSkillsRes = res.filter((title) => title.title !== "");
      this.filteredSkills = this.skillCtrl.valueChanges.pipe(
          startWith(''),
          map(value => this._filterSkill(value))
      );
    });
  }

  private _filterSkill(value: string | Skills): Skills[] {
    const filterValue = typeof value === 'string' ? value.trim().toLowerCase() : value?.title.trim().toLowerCase() ?? '';
    this.exactMatchFound = this.allSkillsRes.some((skill: Skills) => skill.title.toLowerCase() == filterValue);
    console.log('exactMatchFound', this.exactMatchFound);
    return this.allSkillsRes.filter((skill: Skills) =>
        skill.title.toLowerCase().includes(filterValue)
    );
  }
  disableSkill(skill: any): boolean {
    return this.skills.some(selected => selected.id === skill.id) || this.skills.some(s => s.id === skill.id);
  }

  getFormValidationErrors(): string {
    let error = '';
    Object.keys(this.jobForm.controls).forEach(key => {
      const controlErrors = this.jobForm.get(key)?.errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          error = error + key + ', ';
        });
      }
    });

    this.errorShow = error
    return error;
  }

  validateForm(): Promise<void> {
    return new Promise((resolve, reject) => {
      let hasErrors = false;
      Object.values(this.jobForm.controls).forEach(control => {
        if (control.invalid) {
          control.markAsTouched();
          control.setErrors({ invalid: true });
          hasErrors = true;
        }
      });
      if (hasErrors) {
        if (this.jobForm.invalid) {
          this.showloader = false;
          if (this.jobForm.value.contactId === '' && this.jobForm.value.customerContact === '') {
            this.toaster.error('Select the client contact. If unavailable, create it first, then create the job.');

          } else {
            this.toaster.error('Please fill the required fields before submitting the form.');
          }
          return;
        }
        this.showloader = false;

        reject();
      } else {
        resolve();
      }
    });
  }

  selectSkills(event: MatAutocompleteSelectedEvent): void {
    this.skills.push(event.option.value);
    this.chipGrid.nativeElement.value = '';
    this.skillCtrl.setValue(null);
  }

  updatedPayload() {
    let newPayload = this.jobForm.value;
    let selectedSkills: number[] = [];
    this.skills.map((skill: Skill) => {
      selectedSkills.push(skill.id);
    });
    if (!newPayload['workTimeZone']) {
      newPayload['workTimeZone'] = this.workTimeZoneControl.value;
    }
    newPayload['status'] = 'POSTED';
    newPayload['contactId'] = this.jobForm.get('contactId')?.value ?? '';
    newPayload['noOfOpenings'] = this.jobForm.get('noOfOpenings')?.value ?? '';
    newPayload['title'] = this.jobForm.get('title')?.value ?? '';
    newPayload['description'] = this.jobForm.get('description')?.value ?? '';
    newPayload['minExperience'] = this.jobForm.get('minExperience')?.value ?? '';
    newPayload['maxExperience'] = this.jobForm.get('maxExperience')?.value ?? '';
    newPayload = {
      ...newPayload,
      skills: selectedSkills,
      customerId: this.jobForm.value.customerContact?.customer?.id
    };

    return newPayload;
  }


  addJob(): void {
    this.updatedPayload()
    this.jobError = '';
    this.showloader = true;
     if (this.data.jobId) {
       this.service.updateAdminJob(this.updatedPayload(),this.data.jobId).then(res => {
         this.isJobsDataUpdated.setUpdated(true);
         this.dialogRef.close();
       },(e) => {
         this.close();
         if(e.error) {
           this.dialogRef.disableClose;
         } else if (e.error.status == 400) {
           e.error.message;
           this.errorMessage = e.error.message;
           console.log('error-400',this.errorMessage)
         } else if (e.error.status == 500) {
           this.errorMessage = e.error.message;
           console.log('error-500',this.errorMessage)
         }
       })
     } else {
       this.validateForm().then(()=> {
         this.service.addAdminJob(this.updatedPayload()).subscribe({
           next: response => {
             this.isJobsDataUpdated.setUpdated(true);
             this.dialogRef.close();
           },
           error: err => {
             console.error(err);
             this.dialogRef.disableClose;
           }
         })
       })
     }
  }
  openAutocomplete(trigger: MatAutocompleteTrigger) {
    if (trigger) {
      trigger.openPanel();
    }
  }
  addSkillType(): void {
    let payload = {
      'title': this.skillName,
    }
    this.service.addOrgSkills(payload).then(res => {
      this.isJobsDataUpdated.setUpdated(true);
      this.getSkillType();
      this.skills.push(res);
      this.skillName = '';
      this.chipGrid.nativeElement.value = '';
      this.skillControl.setValue('');
    }, (e) => {
      console.error('error', e.error.message);
    })
  }

  removeSkill(skill: string): void {
    const index = this.skills.indexOf(skill);
    if (index >= 0) {
      this.skills.splice(index, 1);
    }
  }

  close() {
    this.dialogRef.close();
  }

  toggleProfileJobStatus(event: any) {
    if(event.checked === true) {
      this.service.jobActive(this.data.jobId).subscribe( {
        next: res =>{
          this.isJobsDataUpdated.setUpdated(true);
          this.jobStatus = true;
          this.toaster.success('Job Status Enabled ' , 'Success');
        }
      }),(e:any)=>{
        this.toaster.error(e.message , 'Request Failed');
      }
    } else {
      this.service.jobInActive(this.data.jobId).subscribe({
        next: res =>{
          this.isJobsDataUpdated.setUpdated(true);
          this.jobStatus = false;
          this.toaster.success('Job Status Disabled ' , 'Success');
        }
      }),(e:any)=>{
        this.toaster.error(e.message , 'Request Failed');
      }
    }
  }

  getAllClientContacts(selectClient:any,type:string) {
    this.service.getContactsByCustomerId((type=='ADD')?selectClient.option.value.id:selectClient).then((res)=>{
      this.clientContactList = res;
    });
  }

  selectContactId(event :any){
    this.jobForm.get('contactId')?.setValue(event.value.id)
  }

  getFormControl(controlName: string): FormControl {
    return this.jobForm.get(controlName) as FormControl;
  }

}
