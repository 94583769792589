import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";


export class CustomValidators{
  static validEmail():ValidatorFn{
    return (control: AbstractControl): ValidationErrors | null => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.(com|in)$/;
      const value = control.value;

      if(!value){
        return null;
      }

      const isValid = emailRegex.test(value);
      return isValid ? null : { invalidEmail: true };
    }
  }
}
