import { AfterViewInit, Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ApiService } from "../../../services/api.service";
import { DatePipe } from "@angular/common";
import Chart from 'chart.js/auto/auto.mjs';
import { DataService } from "../../../services/data.service";
import { MatPaginator } from "@angular/material/paginator";
import { debounceTime, distinctUntilChanged, filter, tap, takeUntil } from 'rxjs/operators';
import { fromEvent, Subject } from 'rxjs';
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ChartDataLabels);

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  dashboard: any | undefined;
  expiringDocs: any[] =[];
  activeVendor: number;
  currentPage = 0;
  totalPages = 0;
  length: any;
  search = '';
  pageSize = 12;
  inActiveVendor: number;
  myDate: any | undefined = new Date();
  endDate: any ;
  barChartData: any[] = [];
  barChartCategory: any[] = [];
  color: any;
  totalDocuments = 0;
  totalConsultants:number
  totalVendors:number
  totalCustomers:number
  totalTasks:number
  activeConsultants:number
  inactiveConsultants:number
  activeCustomers:number
  inactiveCustomers:number
  unreadTasks:number
  completedTasks:number
  tasksOnHold:number
  tasksInProgress:number
  private barChart: Chart | undefined;
  private doughnutChart: Chart<'doughnut', number[], string> | null = null;
  private PieChartConsultants: Chart<'pie', number[], string> | null = null;
  private PieChartTask: Chart<'pie', number[], string> | null = null;
  private PieChartCustomers: Chart<'pie', number[], string> | null = null;
  private PolarChartTasks: Chart<'polarArea', number[], string> | null = null;
  private unsubscribe$ = new Subject<void>();

  @ViewChild(MatPaginator) paginator: MatPaginator | any;
  @ViewChild('input', { static: true }) input: ElementRef | any;

  constructor(
    private datePipe: DatePipe,
    private service: ApiService,
    private data: DataService
  ) { }

  ngOnInit(): void {
    this.data.isLoading.next(true);
    this.getExpiringDocuments(this.currentPage, this.pageSize);
    this.getDashboardStats();
    this.getSkillChart();
    this.getConsultantChartData();
    this.getCustomersChartData();
    this.getTasksChartData();
    this.color = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#6E85B7","secondaryColor":"#6e85b71a"}');
    this.myDate = this.datePipe.transform(this.myDate, 'dd MMM yyyy hh:mm a');
  }

  ngAfterViewInit() {
    this.paginator.page.pipe(
      tap(() => this.getExpiringDocuments(this.paginator.pageIndex, this.paginator.pageSize)),
      takeUntil(this.unsubscribe$)
    ).subscribe();

    if (this.input) {
      fromEvent(this.input.nativeElement, 'keyup')
        .pipe(
          filter(Boolean),
          debounceTime(500),
          distinctUntilChanged(),
          tap(() => this.getExpiringDocuments(this.paginator.pageIndex, this.paginator.pageSize)),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.barChart) {
      this.barChart.destroy();
    }
    if (this.doughnutChart) {
      this.doughnutChart.destroy();
    }
    if (this.PieChartCustomers) {
      this.PieChartCustomers.destroy();
    }
    if (this.PieChartConsultants) {
      this.PieChartConsultants.destroy();
    }
    if (this.PolarChartTasks) {
      this.PolarChartTasks.destroy();
    }
    if (this.PieChartTask) {
      this.PieChartTask.destroy();
    }

  }


  getSkillChart() {
    this.service.getSkillChart().then(res => {
      const response = res;
      this.barChartData = response.map((data: any) => data[0]).slice(0, 10);
      this.barChartCategory = response.map((data: any) => data[1]).slice(0, 10);
      setTimeout(() => {
        this.createBarChart();
        this.data.isLoading.next(false);
      }, 500);
    }, () => {
      this.data.isLoading.next(false);
    });
  }

  getExpiringDocuments(page: number, size: number): void {
    this.service.getExpiringDocuments(page, size).then(res => {
      this.expiringDocs = res.content;
      this.length = res.page.totalElements;
      this.totalPages = res.page.totalPages;
      this.currentPage = res.page.number;
      this.totalDocuments = res.page.totalElements;
      this.data.isLoading.next(false);
    }, () => {
      this.data.isLoading.next(false);
    });
  }

  getDashboardStats(): void {
    this.service.getDashboard().then((res: any) => {
      this.dashboard = res;
      this.activeVendor = res.activeVendor;
      this.inActiveVendor = res.inActiveVendor;
      this.totalVendors = res.totalVendor;
      this.createDoughnutChart();
      this.data.isLoading.next(false);
    }, () => {
      this.data.isLoading.next(false);
    });
  }

  lastDays(date: any) {
    const date1 = new Date(this.myDate);
    const date2 = new Date(date);
    let msBetweenDatesSec = Math.ceil((date2.getTime() - date1.getTime()) / 1000);
    const day = Math.ceil(msBetweenDatesSec / (24 * 3600));
    return day;
  }

  createBarChart(): void {
    const color: any = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#6E85B7","secondaryColor":"#6e85b71a"}');
    const primary = color.primaryColor;
    const barChartElement = document.getElementById("bar-chart");
    if (barChartElement) {
      if (this.barChart) {
        this.barChart.destroy();
      }
      this.barChart = new Chart(barChartElement as HTMLCanvasElement, {
        type: 'bar',
        data: {
          labels: this.barChartCategory,
          datasets: [{
            backgroundColor: primary,
            data: this.barChartData,
          }],
        },
        options: {
          indexAxis: 'y',
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              stacked: true
            },
            y: {
              stacked: true
            }
          }
        }
      });
    }
  }


  createDoughnutChart(): void {
    const color: any = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#6E85B7","secondaryColor":"#6e85b71a"}');
    const doughnutChartElement = document.getElementById("doughnut-chart");
    const totalVendorsElement = document.getElementById("total-Vendors");
    if (totalVendorsElement) {
      totalVendorsElement.textContent = `Total Vendors : ${this.totalVendors}`;
    }
    if (doughnutChartElement) {
      if (this.doughnutChart) {
        this.doughnutChart.destroy();
      }
      this.doughnutChart = new Chart(doughnutChartElement as HTMLCanvasElement, {
        type: 'doughnut',
        data: {
          labels: ['Active Vendors', 'InActive Vendors'],
          datasets: [{
            label: 'Stats',
            data: [this.activeVendor, this.inActiveVendor],
            backgroundColor: [
              color.primaryColor,
              '#ececec',
            ],
          }]
        },
        options: {

          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  family: 'Arial',
                  size: 14,
                  style: 'normal',

                  weight: 'normal'
                },
                boxHeight: 10,
                boxWidth: 10,

              }
            },
            datalabels: {
              color: 'black',
              formatter: (value: number, context: any) => {
                return value;
              }
            }
          }
        }
      });
    }
  }
  getConsultantChartData() {
    this.service.consultantChart().subscribe(
        (res) => {
          const response = res;
          this.activeConsultants=res.activeConsultants
          this.inactiveConsultants=res.inactiveConsultants
          this.totalConsultants=res.totalConsultants
          this.createConsultantChart();
          this.data.isLoading.next(false);
        },
        (error) => {
          this.data.isLoading.next(false);
          console.log('error', error);
        }
    );
  }

  createConsultantChart(): void {
    const color: any = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#6A89CC","secondaryColor":"#a8bff1"}');
    const pieChartConsultantElement = document.getElementById("pie-chart-Consultants");
    const totalConsultantsElement = document.getElementById("total-consultants");
    if (totalConsultantsElement) {
      totalConsultantsElement.textContent = `Total Consultants : ${this.totalConsultants}`;
    }
    if (pieChartConsultantElement) {
      if (this.PieChartConsultants) {
        this.PieChartConsultants.destroy();
      }
      this.PieChartConsultants = new Chart(pieChartConsultantElement as HTMLCanvasElement, {
        type: 'pie',
        data: {
          labels: ['Active Consultants', 'InActive Consultants'],
          datasets: [{
            label: 'Stats',
            data: [this.activeConsultants, this.inactiveConsultants],
            backgroundColor: [
              color.primaryColor,
              '#ececec',
            ],
          }]
        },
        options: {
          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  family: 'Arial',
                  size: 14,
                  style: 'normal',

                  weight: 'normal'
                },
                boxHeight: 10,
                boxWidth: 10,

              }
            },
            datalabels: {
              color: 'black',
              formatter: (value: number, context: any) => {
                return value;
              }
            }
          }
        }
      });

    }
  }

  getCustomersChartData() {
    this.service.customertChart().subscribe(
        (res) => {
          const response = res;
          this.activeCustomers=res.activeCustomers
          this.inactiveCustomers=res.inactiveCustomers
          this.totalCustomers=res.totalCustomers
          this.createCustomersChart();
          this.data.isLoading.next(false);
        },
        (error) => {
          this.data.isLoading.next(false);
          console.log('error', error);
        }
    );
  }
  createCustomersChart(): void {
    const color: any = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#3e3ef8","secondaryColor":"#d3d3d3"}');
    const pieChartCustomerElement = document.getElementById("pie-chart-Customers");
    const totalCustomersElement = document.getElementById("total-customers");
    if (totalCustomersElement) {
      totalCustomersElement.textContent = `Total Customers : ${this.totalCustomers}`;
    }
    if (pieChartCustomerElement) {
      if (this.PieChartCustomers) {
        this.PieChartCustomers.destroy();
      }
      this.PieChartCustomers = new Chart(pieChartCustomerElement as HTMLCanvasElement, {
        type: 'pie',
        data: {
          labels: ['Active Customers', 'InActive Customers'],
          datasets: [{
            label: 'Stats',
            data: [this.activeCustomers, this.inactiveCustomers],
            backgroundColor: [
              color.primaryColor,
              '#ececec',
            ],
          }]
        },
        options: {
          cutout: '75%',
          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  family: 'Arial',
                  size: 14,
                  style: 'normal',

                  weight: 'normal'
                },
                boxHeight: 10,
                boxWidth: 10,

              }
            },
            datalabels: {
              color: 'black',
              formatter: (value: number, context: any) => {
                return value;
              }
            }
          }
        }
      });
    }
  }
   lightenColor(color:any, percent:any) {
    const num = parseInt(color.slice(1), 16),
        amt = Math.round(2.55 * percent),
        R = (num >> 16) + amt,
        G = (num >> 8 & 0x00FF) + amt,
        B = (num & 0x0000FF) + amt;
    return `#${(0x1000000 + (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 + (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 + (B < 255 ? (B < 1 ? 0 : B) : 255)).toString(16).slice(1).toUpperCase()}`;
  }

  getTasksChartData() {
    this.service.tasksChart().subscribe(
      (res) => {
        const response = res;
        this.unreadTasks = res.unreadTasks
        this.completedTasks = res.completedTasks
        this.tasksOnHold = res.tasksOnHold
        this.tasksInProgress = res.tasksInProgress
        this.totalTasks = res.myTotalTasks
        this.createTAskaPieChart();
        this.data.isLoading.next(false);
      },
      (error) => {
        this.data.isLoading.next(false);
        console.log('error', error);
      }
    );
  }

  createTAskaPieChart(): void {
    const color: any = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#6A89CC","secondaryColor":"#a8bff1"}');
    const pieChartTaskElement = document.getElementById("pie-chart-TAsks");
    const lighterShade1 = this.lightenColor(color.primaryColor, 10);
    const lighterShade2 = this.lightenColor(color.primaryColor, 20);
    const totalTAsksElement = document.getElementById("total-Task");
    if (totalTAsksElement) {
      totalTAsksElement.textContent = `Total Tasks : ${this.totalTasks}`;
    }
    const totalUnreadTasksElement = document.getElementById("total-unread-Task");
    if (totalUnreadTasksElement) {
      totalUnreadTasksElement.textContent = `Total Unread Tasks : ${this.unreadTasks}`;
    }
    if (pieChartTaskElement) {
      if (this.PieChartTask) {
        this.PieChartTask.destroy();
      }
      this.PieChartTask = new Chart(pieChartTaskElement as HTMLCanvasElement, {
        type: 'pie',
        data: {
          labels: ['In Progress', 'Completed', 'On Hold'],
          datasets: [{
            label: 'Stats',
            data: [ this.tasksInProgress,   this.completedTasks,   this.tasksOnHold],
            backgroundColor: [
              color.primaryColor,
              lighterShade2,
              lighterShade1,

            ],
          }]
        },
        options: {
          plugins: {
            legend: {
              display: true,
              labels: {
                font: {
                  family: 'Arial',
                  size: 14,
                  style: 'normal',
                  weight: 'normal'
                },
                boxHeight: 10,
                boxWidth: 10,
              }
            },
            datalabels: {
              color: 'black',
              formatter: (value: number, context: any) => {
                return value;
              }
            }
          }
        }
      });

    }
  }

  //  createTasksChart() {
  //   const color = JSON.parse(localStorage.getItem('selectedTheme') || '{"primaryColor":"#3e3ef8","secondaryColor":"#d3d3d3"}');
  //    const lighterShade1 = this.lightenColor(color.primaryColor, 8);
  //    const lighterShade2 = this.lightenColor(color.primaryColor, 20);
  //    const polarChartTasksElement = document.getElementById("polar-chart-Tasks");
  //    const totalTasksElement = document.getElementById("total-Tasks");
  //    if (totalTasksElement) {
  //      totalTasksElement.textContent = `Total Tasks : ${this.totalTasks}`;
  //    }
  //   if (polarChartTasksElement) {
  //     if (this.PolarChartTasks) {
  //       this.PolarChartTasks.destroy();
  //     }
  //     this.PolarChartTasks = new Chart(polarChartTasksElement as HTMLCanvasElement, {
  //       type: 'polarArea',
  //       data: {
  //         labels: ['In Progress', 'Completed', 'On Hold'],
  //         datasets: [{
  //           label: 'Tasks',
  //           data: [  this.tasksInProgress,   this.completedTasks,   this.tasksOnHold],
  //           backgroundColor: [
  //             color.primaryColor,
  //             lighterShade1,
  //             lighterShade2,
  //           ]
  //         }]
  //       },
  //       options: {
  //         scales: {
  //           r: {
  //             angleLines: {
  //               display: true
  //             },
  //             pointLabels: {
  //               display: false
  //             },
  //             ticks: {
  //               display: false,
  //               count: 5
  //             },
  //             suggestedMin: 0,
  //             suggestedMax: 50
  //           }
  //         },
  //         plugins: {
  //           datalabels: {
  //             color: 'black',
  //             // font: {
  //             //   weight: 'bold'
  //             // },
  //             formatter: (value: number, context: any) => {
  //               return value;
  //             },
  //             anchor: 'end',
  //             align: 'end',
  //           }
  //         }
  //       }
  //     });
  //   }
  // }

  moveToTrash(docId: string) {
    this.service.documentTrash(docId).then(() => {
      this.getExpiringDocuments(this.currentPage, this.pageSize);
    });
  }
}
